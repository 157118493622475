.color-red {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 49px;
  height: 24px;
  cursor: pointer;
  padding: 4px;
  background-color: #f54040a8;
  border: 1px solid #bebebe;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.color-green {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 49px;
  height: 24px;
  cursor: pointer;
  padding: 4px;
  background-color: #7cf540ba;
  border: 1px solid #bebebe;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.color-warning {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 49px;
  height: 24px;
  cursor: pointer;
  padding: 4px;
  background-color: #ee9b30eb;
  border: 1px solid #bebebe;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
